// src/App.js
import React from 'react';
import CsvProcessor from './CsvProcessor';
import ConciliadorBancard from './ConciliadorBancard';

const App = () => {
  return (
    <><div>
      <h1>CSV Processor App</h1>
      <CsvProcessor />
    </div><div>
        <h1>Conciliador Bancard</h1>
        <ConciliadorBancard />
      </div></>
  );
};

export default App;

