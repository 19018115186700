// src/CsvProcessor.js
import React, { useState } from 'react';
import axios from 'axios';

const CsvProcessor = () => {
  const [file1, setFile1] = useState(null);
  const [file2, setFile2] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const processCsv = async () => {
    const formData = new FormData();
    formData.append('file1', file1);
    formData.append('file2', file2);
    setIsLoading(true);

    try {
      const response = await axios.post('https://utils.pediboss.com.py/csv/process-csv', formData, {
        responseType: 'blob',  // Para manejar la descarga del archivo
      });

      // Crea un enlace para descargar el archivo
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'result.csv');
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.error('Error al procesar archivos CSV', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
<div style={{ margin: '20px', padding: '20px', border: '1px solid #ccc', borderRadius: '8px', maxWidth: '400px' }}>
      <label style={{ marginBottom: '10px', display: 'block' }}>
        Tookan:
        <input type="file" onChange={(e) => setFile1(e.target.files[0])} style={{ marginLeft: '10px' }} />
      </label>
      <label style={{ marginBottom: '20px', display: 'block' }}>
        Yelo:
        <input type="file" onChange={(e) => setFile2(e.target.files[0])} style={{ marginLeft: '10px' }} />
      </label>
      <button onClick={processCsv} style={{ background: '#4CAF50', color: 'white', padding: '10px', border: 'none', borderRadius: '4px', cursor: 'pointer' }}>
        {isLoading ? 'Procesando...' : 'Procesar'}
      </button>
    </div>
  );
};

export default CsvProcessor;

